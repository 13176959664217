import React, { Component } from 'react';
import { Avatar, Button, Input, message } from 'antd';
import User from '../../Client/User';
import Event from '../Event';

class UserProfile extends Component {

    state = {
        user: null,
        loading: false
    }

    componentDidMount() {
        this.getUser();
    }

    updateProfile = (e) => {
        e.preventDefault();
        let data = {
            name: this.state.user.name
        }

        this.setState({loading: true});
        User.updateUser(this.state.user.id, data).then(
            response => {
                this.setState({loading: false});
                message.success("Profile Successfully updated");
                // trigger this event to for update the user context
                Event.emit("userLoggedIn");
            }
        ).catch(
            error => {
                this.setState({loading: false});
                if(error.response != null)
                    message.error(error.response.data.error_description);
            }
        )
    }

    updateName = (e) => {
        let user = this.state.user;
        user.name = e.target.value

        this.setState({user: {...user}});
    }

    getUser() {
        User.getCurrentUser().then(
            response => {
                this.setState({
                    user: response.data
                });
            }
        ).catch(
            error => {
                console.log(error.response);
            }
        )
    }

    render() {
        if(this.state.user != null)
            return (
                <div className="user-profile" style={{padding: "50px"}}>
                    <div className="avatar">
                        <Avatar icon="user" size={120} src={this.state.user.avatar} />
                    </div>
                    <form onSubmit={this.updateProfile}>
                        <div style={{marginTop: "20px"}}>
                            <Input placeholder="Name" value={this.state.user.name} name="name"
                                onChange={this.updateName} />
                        </div>
                        <div style={{marginTop: "20px"}}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Save</Button>
                        </div>
                    </form>
                </div>
            );

        return <div loading="true"></div>
    }
}

export default UserProfile;