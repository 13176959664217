import React, { Component } from 'react';
import queryString from 'query-string';
import Place from '../../Client/Place';
import { message } from 'antd';
import GoogleMap from '../Map/GoogleMap';
import ReactDOM from 'react-dom';
import PlaceInfoWindow from '../Map/PlaceInfoWindow';
import EventEmitter from '../Event';

class GetDirection extends Component {
    state = {
        map: null
    }

    componentDidMount() {
        let query = queryString.parse(this.props.location.search);
        let placeid = query.placeid !== null ? query.placeid : null;
        Place.getPlace(placeid).then(
            res => {
                let place = res.data;
                this.setMarker(res.data);
                EventEmitter.emit('getdirection', place.latitude, place.longitude, 'walking', place)
            }
        ).catch(
            error => {
                console.log(error);
                message.error("Failed to load direction");
            }
        )
    }

    setMarker = (place) => {
        let marker = new window.google.maps.Marker({
            position: {lat: place.latitude, lng: place.longitude},
            map: this.state.map,
            title: place.name,
            icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=1|' + place.types[0].color + '|FFFFFF'
        });

        // set the center of the map to the place
        let latLng = new window.google.maps.LatLng(place.latitude, place.longitude);
        this.state.map.setCenter(latLng);

        // set the info window of the place
        let infowindow = new window.google.maps.InfoWindow({
            content: `<div id="place-window-${place.id}"></div>`
        });

        infowindow.open(this.state.map, marker);
        setTimeout(() => {
            let infowindowelement = document.getElementById("place-window-" + place.id);
            if(infowindowelement != null)
                ReactDOM.render(<PlaceInfoWindow place={place}></PlaceInfoWindow>, infowindowelement);
        }, 500);
    }

    mapLoaded = (map) => {
        this.setState({map: map});
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", flexGrow: 1}}>
                <GoogleMap  mapLoaded={this.mapLoaded} />
            </div>
        );
    }
}

export default GetDirection;