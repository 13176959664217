import React, { Component } from 'react';
import Resource from '../Resource';
import PlaceCategory from './PlaceCategory'

class CategroyList extends Component {
    
    render() {
        return (
            <div className="category-list">
                <Resource path="/placetype" render={data => {
                    return data.data.map(type => <PlaceCategory key={type.id} name={type.name} image={type.image} type={type.type} id={type.id} color={type.color} />);
                }} />
            </div>
        );
    }
}

export default CategroyList;