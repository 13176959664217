import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link }from 'react-router-dom'

class PlaceCategory extends Component {

    render() {
        return (
            <div className="category">
                <div className="image">
                    <Link to={"/places?category=" + this.props.type}>
                        <img src={this.props.image} alt={this.props.image} />
                    </Link>
                </div>
                <Link to={"/map?category=" + this.props.type}>
                    <p style={{color: '#' + this.props.color}}>{this.props.name}</p>
                </Link>
            </div>
        );
    }
}

PlaceCategory.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default PlaceCategory;