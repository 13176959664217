import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, message } from 'antd';
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import User from '../../Client/User';
import Cookies from '../Cookies';

class LoginForm extends Component {
  state = {
    loading: false,
    redirect: false,
    isLogin: false,
    email: ''
  }

  componentDidMount() {
    let token = Cookies.get("access_token");
    if(token != null)
      this.setState({isLogin: true});

    let email = Cookies.get('login_user_email');
    if(email != null) {
      this.setState({...{email: email}});
      this.props.form.setFieldsValue({
        email: email
      });
    }
    
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true});
        if(values.remember) {
          Cookies.set('login_user_email', values.email);
        }

        let data = {
          email: values.email,
          password: values.password
        }

        User.userLogin(data).then(
          response => {
            this.setState({loading: false});
            this.setState({redirect: true});
          },
          error => {
              this.setState({loading: false});
              if(error != null)
                message.error(error.data.message);
          }
        ).catch(
          error => {
            this.setState({loading: false});
            if(error.response != null)
              message.error(error.data.message);
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.redirect || this.state.isLogin) {
      return <Redirect to='/'/>;
    }

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
          <a className="login-form-forgot">
            Forgot password
          </a>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
            Log in
          </Button>
          Or <Link to="/register">register now!</Link>
        </Form.Item>
      </Form>
    );
  }
}

export const LoginFormComp = Form.create()(LoginForm);