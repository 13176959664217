import React, { Component, createContext  } from 'react';
import Cookies from './Cookies';
import User from '../Client/User';
import Event from './Event';

const { Provider, Consumer } = createContext();

class ContextConfig extends Component {

    state = {
        userLoggedIn: false,
        user: {}
    }

    componentDidMount() {
        let token = Cookies.get("access_token");
        if(token != null) {
            this.getUser();
        }

        Event.on('userLoggedIn', (token) => {
            this.getUser();
        });

        Event.on('userLoggedOut', () => {
            this.setState({
                userLoggedIn: false,
                user: {}
            });
        })
    }

    getUser() {
        User.getCurrentUser().then(
            response => {
                this.setState({
                    userLoggedIn: true,
                    user: response.data
                });
            }
        ).catch(
            error => {
                console.log(error.response);
            }
        )
    }

    render() {
        return (
            <Provider value={{
                userLoggedIn: this.state.userLoggedIn,
                user: this.state.user
            }}>
                {this.props.children}
            </Provider>
        );
    }
}

export { ContextConfig, Consumer };
export default Consumer;