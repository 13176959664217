import BaseClient from './BaseClient';

class PlaceType extends BaseClient {

    /**
     * get all the place types
     */
    getPlaceTypes () {
        return this.get("/placetype");
    }
}

export default new PlaceType();