import React, { Component } from 'react';
import Cookie from '../Cookies';
import { Redirect } from 'react-router-dom'
import User from '../../Client/User';
import { message } from 'antd';
import Event from '../Event';

class Logout extends Component {
    state = {
        redirect: false
    }

    componentDidMount() {
        
        User.userLogout().then(
            reponse => {
                Cookie.remove("access_token");
                Cookie.remove("refresh_token");
                Event.emit("userLoggedOut");
                this.setState({redirect: true});
            }
        ).catch(
            error => {
                if(error.response != null)
                     message.error(error.response.data.message);
            }
        )

    }

    render() {
        if(this.state.redirect)
            return <Redirect to='/login'/>;
        return <div></div>
    }
}

export default Logout;