import { Component } from 'react';
import BaseClient from '../Client/BaseClient';

class Resource extends Component {
    state = {
        loading: false,
        data: []
    }

    componentDidMount() {
        this.setState({loading: true});
        let client = new BaseClient();
        client.get(this.props.path).then( res => {
            this.setState({
                data: res.data,
                loading: false
            })
        });
    }

    render() {
        return this.props.render(this.state);
    }
}

export default Resource;