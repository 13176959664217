import BaseClient from './BaseClient';

class GoogleClient extends BaseClient {
    direction (origin, destination, mode) {
        let url = '/direction?origin=' + origin + '&destination=' + destination
        if(mode != null)
            url += '&mode=' + mode
        return this.get(url)
    }

    searchPlace (url, search) {
        if (url === null)
            url = '/place'

        if (search != null)
            url += '?search=' + search

        return this.get(url);
    }
}

export default new GoogleClient();