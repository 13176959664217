/**
 * Created by Pabz Veroy on 19/07/2017.
 */

class GPS {
    getBearing = (from, to) => {
        // Convert to radians.
        let lat1 = from.lat() * Math.PI / 180;
        let lng1 = from.lng() * Math.PI / 180;
        let lat2 = to.lat() * Math.PI / 180;
        let lng2 = to.lng() * Math.PI / 180;
        // Compute the angle.
        let angle = -Math.atan2(Math.sin(lng1 - lng2) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2));
        if (angle < 0.0)
            angle += Math.PI * 2.0;
        if (angle === 0) {
            angle = 1.5;
        }
        return angle;
    }

    rad = (x) => {
        return x * Math.PI / 180;
    }

    // haversine formula
    getDistance = (p1, p2) => {
        let R = 6378137; // Earth’s mean radius in meter
        let dLat = this.rad(p2.lat() - p1.lat())
        let dLong = this.rad(p2.lng() - p1.lng())
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2)
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        let d = R * c
        return d; // returns the distance in meter
    }
}

export default new GPS();