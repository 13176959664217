import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PlaceType from '../../Client/PlaceType';
import MapTile from '../../Client/MapTile';
import queryString from 'query-string';
import GoogleMap from '../Map/GoogleMap';
import Tiles from '../Map/MapTile';
import Place from '../../Client/Place';
import PlaceInfoWindow from '../Map/PlaceInfoWindow';
import PlaceSearch from '../Forms/PlaceSearch';
import FooterCategory from '../PlaceCategory/FooterCategory'

class Map extends Component {
    state = {
        currentCategoryName: '',
        categoryType: null,
        categories: [],
        footerList: [],
        allTiles: [],
        markers: [],
        map: null,
        currentBounds: null,
    };
    
    componentDidMount() {
        let query = queryString.parse(this.props.location.search);
        let catname = query.category !== null ? query.category : null;
        this.setState({ categoryType: catname});
        PlaceType.getPlaceTypes().then(
            res => {
                if(catname) {
                    let categoryName = res.data.find((type) => catname === type.type);
                    this.setState({ currentCategoryName: categoryName.name });
                }
                this.setState({ categories: res.data });
                let list = res.data.map((cat) => {
                    return  <FooterCategory key={cat.id} category={cat} onClick={() => {
                                this.setState({ categoryType: cat.type});
                                this.setState({ currentCategoryName: cat.name });
                                this.loadTilesPlaces(this.state.currentBounds, cat.type, this.state.map, window.google, this);
                            }} />
                });
                this.setState({ footerList: list });
            }
        )
    }

    loadTiles = (e) => {
        let bounds = e.bounds;
        let boundsText = bounds.ne.lat + " " + bounds.sw.lng + ", " + bounds.ne.lat + " " + bounds.ne.lng + ", " +
        bounds.sw.lat + " " + bounds.ne.lng + ", " + bounds.sw.lat + " " + bounds.sw.lng + ", " + bounds.ne.lat + " " + bounds.sw.lng;
        MapTile.getTilesByBounds(boundsText).then(
            res => {
                // clear all the tiles
                this.state.allTiles.forEach((t) => {
                    t.setMap(null);
                });

                let allTiles = res.data.data.map((tile, index) => {
                    let maptile = Tiles.loadTile(this.state.map, tile.path, tile.id);
                    this.addTileClickListener(maptile, tile.id, this.state.map, this);
                    return maptile;
                });

                this.setState({allTiles: allTiles});
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    };

    addTileClickListener(tile, id, map, obj) {
        const google = window.google;
        google.maps.event.addListener(tile, 'click', function (event)  {
            let coordinatesArray = this.getPath().getArray();
            let bounds = new google.maps.LatLngBounds();
            let coords = coordinatesArray.map((pos) => {
                let myLatLng = new google.maps.LatLng(pos.lat(), pos.lng())
                bounds.extend(myLatLng);
                return {lat: pos.lat(), lng: pos.lng()};
            });
            obj.setState({currentBounds: coords});
            obj.loadTilesPlaces(coords, obj.state.categoryType, map, google, obj);
            // vm.currentBounds = coords;
            // vm.getNearestPlaceByBounds(coords, vm.$route.query.category)
            // vm.map.fitBounds(bounds)
            // vm.$router.push({path: '/tile/' + id + '/places'})
        });

    }

    loadTilesPlaces(bounds, type, map, google, obj) {
        let boundText = ''
        bounds.forEach((loc, index) => {
            boundText += loc.lat + ' ' + loc.lng;
            if (index < (bounds.length - 1))
                boundText += ",";
        });

        Place.getNearestPlaceByBounds(boundText, type).then(
            res => {
                // clear all the markers
                this.state.markers.forEach((m) => {
                    m.setMap(null);
                });

                this.loadMarkers(res.data.data)
                
            }
        );
    }

    loadMarkers = (places) => {
        let markers = places.map((place, index) => {
            let marker = new window.google.maps.Marker({
                position: {lat: place.latitude, lng: place.longitude},
                map: this.state.map,
                title: place.name,
                icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + (index + 1) + '|' + place.types[0].color + '|FFFFFF'
            });

            let infowindow = new window.google.maps.InfoWindow({
                content: `<div id="place-window-${place.id}"></div>`
            });

            marker.addListener('click', () => {
                if (this.state.currentInfoWindow != null)
                    this.state.currentInfoWindow.close()

                //infowindow.setContent(ReactDOMServer.renderToString(<PlaceInfoWindow place={place}></PlaceInfoWindow>));
                this.setState({ currentInfoWindow: infowindow });
                infowindow.open(this.state.map, marker);
                
                // not a good hack, but it workds
                setTimeout(() => {
                    ReactDOM.render(<PlaceInfoWindow place={place}></PlaceInfoWindow>, document.getElementById("place-window-" + place.id));
                }, 500);
                
            });

            return marker;
        });

        this.setState({markers: markers});
    }

    mapLoaded = (map) => {
        this.setState({map: map});
    }

    searchPlaces = (val) => {
        Place.getAll(val, 50, this.categoryType).then(
            res => {
                let places = res.data.data
                if(places.length > 0 && (places[0].latitude !== null && places[0].longitude !== null)) {
                    this.state.map.setCenter({lat: places[0].latitude, lng: places[0].longitude});
                }
                this.loadMarkers(places);
            }
        )
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", flexGrow: 1}}>
                <div className="search-form current-category">
                    <PlaceSearch type={this.categoryType} onSelect={this.searchPlaces}></PlaceSearch>
                    <p>{this.state.currentCategoryName}</p>
                </div>
                <GoogleMap onBoundsChange={this.loadTiles} mapLoaded={this.mapLoaded}></GoogleMap>
                <div className="category-footer">
                    {this.state.footerList}
                </div>
                {/* <Resource path="/placetype" render={data => {
                    this.setState({ categories: data });
                    let categoryName = data.data.find((type) => {
                        if(this.props.param.category === type.name)
                            return type.name;
                    });
                    console.log(categoryName);
                    return <span></span>;
                    //return data.data.map(type => <PlaceCategory key={type.id} name={type.name} image={type.image} type={type.type} id={type.id} color={type.color} />);
                }} />  */}
            </div>
        );
    }
}

Map.propTypes = {

};

export default Map;