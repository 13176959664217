import React, { Component } from 'react';
import { RegisterFormComp } from '../Forms/RegisterForm';

class Register extends Component {
    state = {
        loginForm: null
    }

    render() {
        return (
            <div id="login">
                <RegisterFormComp />
            </div>
        );
    }
}


export default Register;