import React, { Component } from 'react';

class FooterCategory extends Component {
    
    render() {
        return (
            <div className="place-type" style={{backgroundColor: '#' + this.props.category.color}} 
                onClick={this.props.onClick}>
             {/* <img src={cat.image} alt={cat.name} /> */}
                <span style={{color: "#FFFFFF", fontSize: "12px", width: "50px"}}>{this.props.category.name}</span>
        </div>
        );
    }
}

export default FooterCategory;