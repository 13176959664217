import BaseClient from './BaseClient';

class MapTile extends BaseClient {
    
    /**
     * Get Tiles by bounds
     * @param {String} bounds 
     */
    getTilesByBounds (bounds) {
        return this.get(`/maptile?bounds=${encodeURIComponent(bounds)}`);
    }

}

export default new MapTile();