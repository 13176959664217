import axios from 'axios';
import Cookies from '../Components/Cookies';
import Event from '../Components/Event';

class BaseClient {
    constructor() {
        this.baseUrl = 'https://cityapi.iguides.net/v1/api';
        this.setDefulatHeaders();

        // listen to login event to have updated access token
        Event.on('userLoggedIn', (token) => {
            this.setDefulatHeaders();
        });

        // listen to logout event, the update the default headers
        Event.on('userLoggedOut', () => {
            this.setDefulatHeaders();
        })
    }

    setDefulatHeaders() {
        this.defaultHeaders = {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('access_token')
            }
        }
    }

    get (uri, headers) {
        return axios.get(this.baseUrl + uri, headers);
    }

    post (uri, data, headers) {
        return axios.post(this.baseUrl + uri, data, headers);
    }

    put (uri, data, headers) {
        return axios.put(this.baseUrl + uri, data, headers);
    }

    delete (uri, headers) {
        return axios.delete(this.baseUrl + uri, headers);
    }
    
    login (data) {
        return axios.post('https://cityapi.iguides.net/v1/oauth/token', data);
    }
}

export default BaseClient;