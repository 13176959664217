import React, { Component } from 'react';

class Icon extends Component {
    render() {
        return (
            <React.Fragment>
                <i className={this.props.type} style={this.props.style}></i>
            </React.Fragment>
        );
    }
}

export default Icon;