import React, { Component } from 'react';
import { LoginFormComp } from '../Forms/LoginForm';

class Login extends Component {
    state = {
        loginForm: null
    }

    render() {
        return (
            <div id="login">
                <LoginFormComp />
            </div>
        );
    }
}


export default Login;