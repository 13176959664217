import React, { Component } from 'react';
import PlaceType from '../../Client/PlaceType';
import Place from '../../Client/Place';
import queryString from 'query-string';
import PlaceSearch from '../Forms/PlaceSearch';
import PlaceCarousel from '../Place/PlaceCarousel';
import FooterCategory from '../PlaceCategory/FooterCategory';
import EventEmitter from '../Event';
import { message, Carousel, Skeleton } from 'antd';

class Places extends Component {
    state = {
        currentCategoryName: '',
        categoryType: null,
        categories: [],
        footerList: [],
        userPosition: {
            lat: 51.490744,
            lng: -0.1403619
        },
        places: [],
        loading: false
    };
    
    componentDidMount() {
        let query = queryString.parse(this.props.location.search);
        let catname = query.category !== null ? query.category : null;
        this.setState({ categoryType: catname});
        PlaceType.getPlaceTypes().then(
            res => {
                if(catname) {
                    let categoryName = res.data.find((type) => catname === type.type);
                    this.setState({ currentCategoryName: categoryName.name });
                }
                this.setState({ categories: res.data });
                let list = res.data.map((cat) => {
                    return <FooterCategory key={cat.id} category={cat} onClick={() => {
                        this.setState({ categoryType: cat.type});
                        this.setState({ currentCategoryName: cat.name });
                        this.getNearestPlaces(cat.type);
                    }} />
                });
                this.setState({ footerList: list });
            }
        )

        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                this.setState({...{userPosition: pos}});
                this.getNearestPlaces(catname);
            }, (error) => {
                // message.error('Please allow the app to get your location for better experience');
                this.getNearestPlaces(catname);
            }, { timeout: 1000 });
        } 

        EventEmitter.on('userchangelocation', (pos) => {
            this.setState({...{userPosition: pos}});
       });
    }

    getNearestPlaces = (catname) => {
        this.setState({...{loading: true}});
        Place.getNearestPlaces(this.state.userPosition.lat, this.state.userPosition.lng, 500, catname).then(
            res => {
                this.setState({...{loading: false}});
                this.setState({...{places: res.data}});
            }
        ).catch(
            error => {
                message.error('Error Loading Places. Please try again');
            }
        )
    }

    searchPlaces = (val) => {
        // TODO: implement search places
        Place.getAll(val, 50, this.state.categoryType).then(
            res => {
                this.setState({...{places: res.data.data}});
            }
        ).catch(
            error => {
                message.error('Error Loading Places. Please try again');
            }
        )
    }

    loadPlaces = () => {
        if(this.state.loading) {
            return <Skeleton active />
        } else {
            if(this.state.places.length > 1)
                return <Carousel dots={false}>
                    {  this.state.places.map((place) => {
                        return <PlaceCarousel place={place} key={place.id} />
                    })}
                </Carousel>
            else
                return this.state.places.map((place) => {
                    return <PlaceCarousel place={place} key={place.id} />
                })
        }
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", flexGrow: 1}}>
                <div className="search-form current-category">
                    <PlaceSearch type={this.state.categoryType} onSelect={this.searchPlaces}></PlaceSearch>
                    <p>{this.state.currentCategoryName}</p>
                </div>
                <div style={{ width: '100%', flexGrow: 3, position: "relative", overflow: "scroll" }}>
                        {this.loadPlaces()}
                </div>
                <div className="category-footer">
                    {this.state.footerList}
                </div>
            </div>
        );
    }
}

export default Places;