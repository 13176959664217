class MapTile {

    loadTile (map, path, id) {
        const google = window.google;
        let maptile = new google.maps.Polygon({
            paths: path,
            strokeColor: '#FF0000',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#FF0000',
            fillOpacity: 0.2,
            indexID: id || 0
        });
        maptile.setMap(map);

        return maptile;
    }
}


export default new MapTile();