import React, { Component } from 'react';
import { ChangePasswordComp } from '../Forms/ChangePasswordForm';

class ChangePassword extends Component {
    state = {
        loginForm: null
    }

    render() {
        return (
            <div id="login">
                <ChangePasswordComp />
            </div>
        );
    }
}


export default ChangePassword;