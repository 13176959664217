import React, { useState } from 'react';
import { Drawer, Avatar } from 'antd';
import Icon from '../Utils/Icon';
import { Link } from 'react-router-dom';
import Consumer from '../ContextConfig';

const Header = () => {
    const [visible, showDrawer] = useState(false);
    const onShowDrawerClick = (event) => showDrawer(true);
    const onCloseDrawer = (event) => showDrawer(false);

    return (
        <div className="header" style={{background: "#3f51b5", height: "60px", position: "relative"}}>
            <div className="menu-button" style={{position: "absolute", left: "5px", top: "0", padding: "4px", margin: "10px 0 0 10px"}}  onClick={() => {
                        window.history.back();
                    }}>
                <Icon type="fa fa-angle-left" style={{fontSize: "28px", color: "#FFFFFF", cursor: "pointer"}} />
            </div>
            <h1 style={{color: "#FFFFFF", display: "inline-block", width: "100%", margin: "8px 0 0 0", fontSize: "24px"}}>City Guide</h1>
            <div className="menu-button" style={{position: "absolute", right: "5px", top: "0", padding: "4px", margin: "10px 10px 0 0"}} onClick={onShowDrawerClick}>
                <Icon type="fa fa-bars" style={{fontSize: "28px", color: "#FFFFFF", cursor: "pointer"}} />
            </div>
            <Drawer
                title="Menu"
                placement="right"
                closable={true}
                onClose={onCloseDrawer}
                visible={visible}
            >
            <Consumer>
                {ctx => {
                    if(ctx.userLoggedIn) {
                        return (
                            <div className="userProfile">
                                <div style={{width: "64px"}}>
                                    <Avatar size={64} icon="user" src={ctx.user.avatar} />
                                </div>
                                <p>{ctx.user.name}</p>
                            </div>
                        )
                    } else {
                        return <div></div>
                    } 
                }}
            </Consumer>
            <p><Link to="/" onClick={onCloseDrawer}>Home</Link></p>
            <p><Link to="/places?category=restaurant" onClick={onCloseDrawer}>Places</Link></p>
            <p><Link to="/map?category=restaurant" onClick={onCloseDrawer}>Map</Link></p>
            <Consumer>
                {ctx => {
                    if(ctx.userLoggedIn) {
                        return (
                            <React.Fragment>
                                <p><Link to="/profile" onClick={onCloseDrawer}>Profile</Link></p>
                                <p><Link to="/changepassword" onClick={onCloseDrawer}>Change Password</Link></p>
                                <p><Link to="/logout" onClick={onCloseDrawer}>Logout</Link></p>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment>
                                <p><Link to="/login" onClick={onCloseDrawer}>Login</Link></p>
                                <p><Link to="/register" onClick={onCloseDrawer}>Register</Link></p>
                            </React.Fragment>
                        );
                    }
                }}
            </Consumer>
            </Drawer>
        </div>
    );
}

export default Header;