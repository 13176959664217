import React, { Component } from 'react';
import { Icon, Button, Input, AutoComplete } from 'antd';
import Place from '../../Client/Place';

const { Option } = AutoComplete;

class PlaceSearch extends Component {
    state = {
        dataSource: []
    }

    handleSearch = (value) => {
        if(this.state.timeout != null)
            clearTimeout(this.state.timeout);

        let time = setTimeout(() => {
            Place.searchPlaceName(value, this.props.type).then(
                res => {
                    this.setState({ ...{dataSource: res.data} });
                }
            );
        }, 500);

        this.setState({ ...{timeout: time} });
        
    };

    renderOption = (place) => {
        return (
            <Option key={place} text={place}>
              <div className="global-search-item">
                <span className="global-search-item-desc">
                    {place}
                </span>
                {/* <span className="global-search-item-count">{item.count} results</span> */}
              </div>
            </Option>
          );
    }

    render() {
        return (
            <div style={{width: 'auto', display: 'flex', flexDirection: 'column'}}>
                <AutoComplete
                    className="global-search"
                    size="default"
                    style={{boxSizing: 'border-box', width: '100%'}}
                    dataSource={this.state.dataSource.map(this.renderOption)}
                    onSelect={this.props.onSelect}
                    onSearch={this.handleSearch}
                    placeholder="Enter Search..."
                    optionLabelProp="text"
                    >
                    <Input
                        suffix={
                        <Button
                            className="search-btn"
                            style={{ marginRight: -12 }}
                            size="default"
                            type="primary"
                        >
                            <Icon type="search" />
                        </Button>
                        }
                    />
                </AutoComplete>
            </div>
        );
    }
}

export default PlaceSearch;