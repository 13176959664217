import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import CategoryList from '../PlaceCategory/CategoryList';

class Home extends Component {
    // constructor(props) {
    //     super(props);

    // }
    
    componentDidMount() {

    }

    render() {
        return (
            <div className="content">
                <h2>Welcome to City Guide</h2>
                <CategoryList />
            </div>
        );
    }
}

// Home.propTypes = {

// };

export default Home;