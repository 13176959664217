import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import GPS from '../Utils/GPS';
import GoogleClient from '../../Client/GoogleClient';
import lineDecoder from 'decode-google-map-polyline'
import { Button } from 'antd';
import EventEmitter from '../Event';

class GoogleMap extends Component {
    state = {
        map: null,
        origin: null,
        lastPosition: null,
        directionSteps: [],
        locationSet: false,
        routeslines: []
    }

    static defaultProps = {
        center: {
            lat: 51.490744,
            lng: -0.1403619
        },
        zoom: 14,
        key: "AIzaSyBvCOLkh5pqMbbJ05_cs90gWx2wSv2cysg"
    };

    mapLoaded = (map) => {
        this.props.mapLoaded(map.map)
        this.loadUserLocation(map.map);
    }

    loadUserLocation = (map) => {
        const google = window.google;
        let gpsImage = new Image()
        gpsImage.src = require('../../Assets/images/gps_icon.png')

        let origin = new google.maps.Marker({
            position: {lat: 51.490744, lng: -0.1403619},
            map: map,
            // draggable: true,
            title: 'Current Location',
            icon: {
                url: gpsImage.src,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 0)
            }
        })
        this.setState({...{map: map}});
        this.setState({ origin: origin });
        this.getUserLocation(map);
    }

    getUserLocation = (map) => {
        const google = window.google;
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition((position) => {
                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }

                let currentPosition = new google.maps.LatLng(pos)

                // reset the last position if move 5 meters
                if (this.state.lastPosition == null) {
                    let lastPosition = new google.maps.LatLng(pos)
                    this.setState({ lastPosition: lastPosition });
                } else {
                    // if (User.isAuthenticated())
                    let distance = GPS.getDistance(this.state.lastPosition, currentPosition)
                    if (distance < 5.0) {
                        return false
                    }
                }

                // for (let x = 0; x < vm.directionSteps.length; x++) {
                //     if (!vm.directionSteps[x].spoken) {
                //         let routePosition = new google.maps.LatLng(vm.directionSteps[x].position)
                //         let dist = GPS.getDistance(routePosition, currentPosition)
                //         // if (dist <= 5.0) {
                //         //     Speech.speak(vm.directionSteps[x].instruction.replace(/<(?:.|\n)*?>/gm, ''))
                //         //     vm.directionSteps[x].spoken = 1
                //         // }
                //     }
                // }

                // let angle = GPS.getBearing(vm.origin.getPosition(), currentPosition)
                let latLng = new google.maps.LatLng(pos.lat, pos.lng);
                // vm.getBearingAngle(angle)
                if (!this.state.locationSet) {
                    // map.setCenter(latLng)
                    this.setState({ locationSet: true });
                }

                // let data = {
                //     latitude: pos.lat,
                //     longitude: pos.lng
                // }

                // User.saveUserLocation(data)
                // map.setCenter(latLng)
                let lastPosition = new google.maps.LatLng(pos)
                this.setState({ lastPosition: lastPosition });
                this.state.origin.setPosition(latLng)
                EventEmitter.emit('userchangelocation', pos);
            })
        }
    }

    getDirection = (lat, lng, mode, place) => {
        // reset the route lines
        this.state.routeslines.forEach((line) => {
            line.setMap(null);
        });
         
        // reset the directions
        this.setState({ directionSteps: [] });

        // if (place != null)
        //     Speech.speak("Setting route to: " + place.name)

        GoogleClient.direction(this.state.origin.getPosition().lat() + ',' + this.state.origin.getPosition().lng(), lat + ',' + lng, mode)
            .then(result => {
                if (result.data.status === 'OK') {
                    this.setState({ routeslines: [] });
                    this.setState({ loading: false });

                    let directions = result.data.routes.map((route) => {
                        // this.routes.push(result.data.routes[x]) ????
                        let destination = {lat: lat, lng: lng};
                        let direction = this.addPolyLine(route.overview_polyline.points, '#00FF00', destination);

                        route.legs.forEach((leg) => {
                            if(leg !== null && leg.steps != null)
                                this.getSteps(leg.steps);
                        });

                        return direction;
                    });

                    this.setState({ directionSteps: directions });

                } else {
                    this.loading = 0
                }
            })
    }

    addPolyLine = (points, color, destination) => {
        let path = lineDecoder(points);
        // Add the start and destination point on the path
        path.unshift({
            lat: this.state.origin.getPosition().lat(),
            lng: this.state.origin.getPosition().lng()
        })
        path.push(destination)
        var direction = new window.google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 4
        })

        direction.setMap(this.state.map)
        return direction;
    }

    getSteps = (steps) => {
        steps.forEach((step) => {
            if(step.html_instructions != null) {
                let instruction = {
                    instruction: step.html_instructions,
                    position: step.start_location,
                    spoken: 0
                }

                this.setState({ directionSteps: {...instruction}});
                if(step.steps != null)
                    this.getSteps(step.steps);
            }
        });
    }

    setMapCenterToUserLocation = () => {
        this.state.map.setCenter(this.state.lastPosition);
    }

    componentDidMount() {
        EventEmitter.on('getdirection', (lat, lng, mode, place) => {
             this.getDirection(lat, lng, mode, place);
        })
    }
    
    render() {
        return (
            <div style={{ width: '100%', flexGrow: 5, position: "relative", display: "flex", flexDirection: "column", alignItems: "stretch"}} id="google-map">
                <Button type="danger" shape="circle" icon="compass" size="large"
                    style={{ position: "absolute", zIndex: 999, bottom: "9px", left: "9px" }}
                    onClick={this.setMapCenterToUserLocation} />
                <GoogleMapReact
                    bootstrapURLKeys={{ key: this.props.key}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    onChange={this.props.onBoundsChange}
                    onGoogleApiLoaded={this.mapLoaded}
                    yesIWantToUseGoogleMapApiInternals={true}
                ></GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;