import React from 'react';
import './App.css';
import Header from './Components/Layouts/Header';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Home from './Components/Pages/Home';
import Map from './Components/Pages/Map';
import Places from './Components/Pages/Places';
import GetDirection from './Components/Pages/GetDirection';
import Login from './Components/Pages/Login';
import Register from './Components/Pages/Register';
import { ContextConfig } from './Components/ContextConfig'
import Logout from './Components/Pages/Logout';
import ChangePassword from './Components/Pages/ChangePassword';
import UserProfile from './Components/Pages/UserProfile';

function App() {
  return (
      <div className="App" style={{display: "flex", flexDirection: "column", margin: "0 auto"}}>
        <ContextConfig>
          <Router>
              <Header />
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/map" component={Map} />
              <Route path="/places" component={Places} />
              <Route path="/getdirection" component={GetDirection} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/changepassword" component={ChangePassword} />
              <Route path="/logout" component={Logout} />
              <Route path="/profile" component={UserProfile} />
          </Router>
        </ContextConfig>
      </div>
  );
}

export default App;
