import BaseClient from './BaseClient';

class Place extends BaseClient {

    getPlace(id) {
        return this.get('/place/' + id)
    }

    getAll(search, limit, type, page, sort, order) {
        if (limit == null)
            limit = 20

        let url = '/place?limit=' + limit
        if (search != null)
            url += '&search=' + search

        if (type != null)
            url += '&type=' + type

        if (page != null)
            url += '&page=' + page

        if (sort != null)
            url += '&sort_by=' + sort

        if (order != null)
            url += '&order=' + order

        return this.get(url)
    }

    getNearestPlaces(lat, lng, distance, type, userInterest) {
        let url = '/place/nearest?latitude=' + lat + '&longitude=' + lng;

        if (distance != null)
            url += '&distance=' + distance;

        if (type != null)
            url += '&type=' + type;

        if (userInterest != null)
            url += '&user_interest=1';

        return this.get(url, this.defaultHeaders);
    }

    getNearestPlaceByBounds(bounds, type) {
        let uri = `/place/nearestbybounds?bounds=${bounds}`;

        if (type != null)
            uri += `&type=${type}`;

        return this.get(uri, this.defaultHeaders);
    }

    searchPlaceName(search, type) {
        let url =`/search/places?search=${search}`;
        if(type != null)
            url += `&type=${type}`;

        return this.get(url, BaseClient.defaultHeaders);
    }

    bookmarkPlace(userId, placeId, order) {
        let data = {
            place_id: placeId,
            order: order
        }
        return this.post(`/user/${userId}/bookmark`, data, this.defaultHeaders)
    }

    unbookmarkPlace(userId, placeId) {
        return this.delete(`/user/${userId}/bookmark/${placeId}`, this.defaultHeaders)
    }

}

export default new Place();