import React, { Component } from 'react';
import { Button } from 'antd';
import EventEmitter from '../Event';

class PlaceInforWindow extends Component {
    getTypes = (types) => {
        if(types === null)
            return null;
        
        return types.map((t, index) => {
            return <span key={t.id}>{index > 0 ? ',' : ''} {t.name}</span>
        });
    }

    getDirection = () => {
        EventEmitter.emit('getdirection', this.props.place.latitude, this.props.place.longitude, 'walking', this.props.place)
    }

    render() {
        return (
            <div className="info-window-direction">
                <div className="info-window-place-image">
                    <img src={this.props.place.image} alt={this.props.place.name} />
                </div>
                <div className="info-window-place-details">
                    <p><b>{ this.props.place.name }</b></p>
                    {this.getTypes(this.props.place.types)}
                    <div className="buttons">
                        <Button size="small" type="primary">Details</Button>
                        <Button size="small" type="primary" style={{ marginLeft: "3px" }} onClick={() => {
                            this.getDirection();
                            }}>Direction</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlaceInforWindow;