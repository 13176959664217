import UniversalCookie from 'universal-cookie';

class Cookies {
    constructor() {
        this.cookie = new UniversalCookie();
    }

    get(key) {
        return this.cookie.get(key);
    }

    set(key, value) {
        this.cookie.set(key, value)
    }

    remove(key) {
        this.cookie.remove(key)
    }
}

export default new Cookies();