import React, { Component } from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import Cookies from '../Cookies';
import User from '../../Client/User';

class RegisterForm extends Component {
  state = {
    loading: false,
    redirect: false,
    isLogin: false
  }

  componentDidMount() {
    let token = Cookies.get("access_token");
    if(token != null)
      this.setState({isLogin: true});
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(values.password !== values.confirmpassword) {
          message.error("Password doest not match");
        } else {
          let data = {
            name: values.name,
            email: values.email,
            password: values.password,
            password_confirmation: values.confirmpassword,
            type: 'member'
          };

          this.setState({loading: true});

          User.register(data).then(
            response => {
              User.userLogin(data).then(
                response => {
                  this.setState({loading: false});
                  this.setState({redirect: true});
                },
                error => {
                    this.setState({loading: false});
                    if(error != null)
                      message.error(error.data.message);
                }
              ).catch(
                error => {
                  this.setState({loading: false});
                  if(error.response != null)
                    message.error(error.data.message);
                }
              );
            }
          ).catch(
            error => {
              this.setState({loading: false});
              if(error.response != null)
                message.error(error.response.data.error_description);
            }
          );
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.redirect || this.state.isLogin) {
      return <Redirect to='/'/>;
    }

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input your Name!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Name"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your Email!' }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirmpassword', {
            rules: [{ required: true, message: 'Confirm Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
            Register
          </Button>
          Or <Link to="/login">Login</Link>
        </Form.Item>
      </Form>
    );
  }
}

export const RegisterFormComp = Form.create()(RegisterForm);