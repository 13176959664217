import BaseClient from './BaseClient'
import Cookies from '../Components/Cookies';
import Event from '../Components/Event';

class User extends BaseClient {

    /**
     * register new user
     * @param {*} data 
     */
    register(data) {
        return this.post('/register', data)
    }

    /**
     * user login
     * @param {*} data 
     */
    userLogin(data) {
        return new Promise(async (resolve, reject) => {
            let values = {
                client_id: '2',
                // client_secret: 'ntJMmamYkuZBQXVMB3Scd8Q1XJDyB3DVc3O1veVE', local
                client_secret: 'iCjreg4NGi989iialOAs7V7Ebb2QNLmdpFFZJ41j', // live
                grant_type: 'password',
                username: data.email,
                password: data.password
            }

            await this.login(values).then(
                response => {
                    Cookies.set("access_token", response.data.access_token);
                    Cookies.set("refresh_token", response.data.refresh_token);
                    Event.emit('userLoggedIn', response.data);
                    resolve(response.data);
                }
            ).catch(
                error => {
                    console.log(error.response);
                    reject(error.response);
                }
            );
        });
    }

    userLogout() {
        return this.post('/user/logout', {}, this.defaultHeaders);
    }

    // setAuthToken (token) {
    //     let expires = new Date(token.expires_in)
    //     localStorage.setItem("CityGuide.AuthToken", token.access_token)
    //     localStorage.setItem("CityGuide.RefreshToken", token.refresh_token)
    //     localStorage.setItem("CityGuide.TokenExpires", expires)
    //     BaseClient.defaultHeaders = {
    //         headers: {Authorization: 'Bearer ' + token.access_token}
    //     }
    // }

    getCurrentUser () {
        return this.get("/me", this.defaultHeaders)
    }

    isAuthenticated () {
        let token = localStorage.getItem("CityGuide.AuthToken")
        return (token != null)
    }

    getItineraries () {
        return this.get("/user/itineraries", this.defaultHeaders)
    }

    addUserItinerary (data) {
        return this.post("/user/itinerary", data, this.defaultHeaders)
    }

    updateUser (id, data) {
        return this.put("/user/" + id, data, this.defaultHeaders)
    }

    searchUser (search) {
        return this.get("/user?search=" + search, this.defaultHeaders)
    }

    getFollowers (search) {
        return this.get("/user/followers?search=" + search, this.defaultHeaders)
    }

    getUserFollowed (search) {
        return this.get("/user/following?search=" + search, this.defaultHeaders)
    }

    followUser (data) {
        return this.post("/user/follow", data, this.defaultHeaders)
    }

    unFollowUser (userId) {
        return this.delete("/user/unfollow?user_id=" + userId, this.defaultHeaders)
    }

    updateShowLocation (data) {
        return this.put("/user/show/location", data, this.defaultHeaders)
    }

    saveUserLocation (data) {
        return this.post("/user/location", data, this.defaultHeaders)
    }

    getUserLocation (userId, date) {
        let url = "/user/" + userId + "/location";
        if(date != null)
            url += "?date=" + encodeURI(date)
        return this.get(url, this.defaultHeaders)
    }

    getUserFeedbacks () {
        return this.get("/user/feedbacks", this.defaultHeaders)
    }

    changePassword (data) {
        return this.post("/user/changepass", data, this.defaultHeaders)
    }

    bookmark (userId) {
        return this.get("/user/" + userId + "/bookmark", this.defaultHeaders)
    }
}

export default new User();