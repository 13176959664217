import React, { Component } from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import { Redirect } from 'react-router-dom'
import User from '../../Client/User';
import Cookies from '../Cookies';

class ChangePasswordForm extends Component {
  state = {
    loading: false,
    redirect: false,
    isLogin: true,
  }

  componentDidMount() {
    let token = Cookies.get("access_token");
    if(token == null)
      this.setState({isLogin: false});

  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true});
        if(values.password !== values.confirmpassword) {
          message.error("Password doest not match");
        } else if(values.password === values.oldpassword) {
          message.error("New password is same with the old password");
        } else {
          let data = {
            new_pass: values.password,
            old_pass: values.oldpassword
          }
          User.changePassword(data).then(
            response => {
              this.setState({loading: false});
              message.success("Password successfully changed");
              this.props.form.setFieldsValue({
                oldpassword: '',
                password: '',
                confirmpassword: ''
              });
            }
          ).catch(
            error => {
              this.setState({loading: false});
              if(error.response !== null)
                    message.error(error.response.data.error_description);
            }
          )
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.redirect || !this.state.isLogin) {
      return <Redirect to='/'/>;
    }

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('oldpassword', {
            rules: [{ required: true, message: 'Please input your Old Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Old Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your New Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="New Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirmpassword', {
            rules: [{ required: true, message: 'Please confirm your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
            Change password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export const ChangePasswordComp = Form.create()(ChangePasswordForm);