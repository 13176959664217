import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StarRating from 'react-star-ratings';
import { Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { Consumer } from '../ContextConfig';
import Place from '../../Client/Place';

class PlaceCarousel extends Component {
    state = {
        bookMarkLoading: false,
        bookmark: false
    }

    componentDidMount() {
        this.setState({bookmark: this.props.place.bookmarked});
    }

    bookMarkPlace = (userId, placeId) => {
        this.setState({bookMarkLoading: true});
        Place.bookmarkPlace(userId, placeId, 0).then(
            response => {
                this.setState({bookMarkLoading: false});
                message.success("Successfully bookmark place");
                this.setState({bookmark: true});
            }
        ).catch(
            error => {
                this.setState({bookMarkLoading: false});
                if(error.response !== null)
                    message.error(error.response.data.error_description);
            }
        )
    }

    unBookmarkPlace = (userId, placeId) => {
        this.setState({bookMarkLoading: true});
        Place.unbookmarkPlace(userId, placeId).then(
            response => {
                this.setState({bookMarkLoading: false});
                message.success("Unbookmark place");
                this.setState({bookmark: false});
            }
        ).catch(
            error => {
                this.setState({bookMarkLoading: false});
                if(error.response !== null)
                    message.error(error.response.data.error_description);
            }
        )
    }

    openingHours = (place) => {
        if(place.fields.opening_hours != null)
            return place.fields.opening_hours.weekday_text.map((day, index) => {
                return <span style={{display: "block", marginLeft: "15px"}} key={index}>{day}</span>
        });
    }
    
    render() {
        return (
            <div className="place-carousel">
                <div className="place-header" style={{backgroundImage: `url(${this.props.place.image})`}}>
                    <div className="place-name">
                        <h3>{this.props.place.name}</h3>
                    </div>
                </div>
                <div className="place-details">
                    <span><b>Rating: </b></span>
                    <StarRating rating={parseInt(this.props.place.fields.rating)}
                        starDimension="20px"
                        starSpacing="2px"
                        starRatedColor="gold" />
                        <p><b>Address:</b> {this.props.place.address}</p>
                        <p><b>Phone:</b> {this.props.place.fields.phone}</p>
                        <p><b>Category:</b> {this.props.place.types.map((t, index) => {
                            return <span key={t.id}>{index > 0 ? ',' : ''} {t.name}</span>
                        })}</p>
                        <p><b>Opening Hours:</b>
                            {this.openingHours(this.props.place)}
                        </p>
                        <Link to={`/getdirection?placeid=${this.props.place.id}`}>
                            <Button size="default" type="primary">Get Direction</Button>
                        </Link>
                        <Consumer>
                            {(ctx) => {
                                if(ctx.userLoggedIn) {
                                    if(!this.state.bookmark)
                                        return <Button type="primary" style={{ marginLeft: "10px" }} 
                                        onClick={() => { this.bookMarkPlace(ctx.user.id, this.props.place.id);}} loading={this.state.bookMarkLoading}>Bookmark</Button>
                                    else
                                        return <Button type="primary" style={{ marginLeft: "10px" }} 
                                        onClick={() => { this.unBookmarkPlace(ctx.user.id, this.props.place.id)}} loading={this.state.bookMarkLoading}>Unbookmark</Button>
                                }
                            }}
                        </Consumer>
                </div>
            </div>
        );
    }
}

PlaceCarousel.propTypes = {
    place: PropTypes.object.isRequired,
};

export default PlaceCarousel;